import React, { useState  } from 'react';
import { useParams } from 'react-router'

const Msg = () => {
    const { message_url_id } = useParams();
    const [sid,setsid]=useState(message_url_id);
    const [data, setData] = useState(null)


    const onClick = async() =>{
       
        try {
            const data111 = await (await fetch(  "https://server.ckveron.com/api/getchat?key=" + sid  )).json()
            console.log( JSON.stringify(data));
            setData(data111)
           
            
        } catch (err) {
             console.log("eeerrrrr======" + err.message)
        }

       
        

    }

    function checkResponse(data) {
       
        if (data) {
            
            if(data.file){
                return(
                    <div className="modal-body" >
                      
                 <h5 className="text-danger">Message</h5>
                         
                         <textarea className="form-control mb-2" rows="6" name="myMessage" value={data.message}
                
              ></textarea>
    
      
      <h5 className="text-danger">File</h5>
      <p><a href={data.file} >Download File</a></p>
                    </div>
                
                
                ) 
                
            }
            else{
                return(
                    <div className="modal-body" >
                        <h5 className="text-danger">Message</h5>
                        <textarea className="form-control mb-2" rows="6" name="myMessage" value={data.message} ></textarea>
                    </div>
                
                
                ) 

            }
            
            
        } else {
            return (
                <div className="modal-body" >
                        <h5 className="text-danger">Loading...</h5>
                        
                    </div>
            )
        }

    }

const CloseModal =()=>{
    setData(null)
}
   

    return (

        <div>
            



            

            <button className="btn btn-outline-primary form-control" data-bs-toggle="modal" data-bs-target="#myModal" onClick={onClick}>Message Will Only show Once</button>

             <div class="modal" id="myModal">
                <div class="modal-dialog">
                    <div class="modal-content">


                        <div class="modal-header">
                            {/* <h4 class="modal-title">Chat Histort</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal"></button> */}
                        </div>


                        {/* <div class="modal-body">  /// </div> */}
                             
                        {checkResponse(data)}



                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={CloseModal}>Close</button>
                        </div>

                    </div>
                </div>
            </div>
            
        </div>
        

    )

    
}

export default Msg