import React ,{Fragment} from 'react';
import CreateChat from './components/Feature';
import './App.css';
import {   Route, Routes  ,  } from 'react-router-dom';
import Msg from './components/Msg'


import Navibar from './components/Navbar'
import Footer from './components/Footer';

function App() {
  return (
    <div className='App'>
      <Navibar />
      <Routes>
        <Route path="*" element={<CreateChat />} />
        {/* <Route path="/auth" element={this.state.token ? <Navigate to="/" /> : Display2} /> */}
        
        <Route exact path="Msg/:message_url_id" element={<Msg />} />
        {/* <Route path="*" element={<SSS />} /> */}
      </Routes>
      <Footer />
    </div>

    
    )
}

export default App;
