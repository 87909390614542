import React, { useState } from 'react'

const Footer = () =>{

    const [showFaq, setShowFaq] = useState(false);
    const toggleFaq = (e) => {
        setShowFaq(!showFaq);
      };
  return (
    
      <div className="m-3 pb-5">
        <h5 id="faq" className="margin-top:50px;margin-bottom:20px;text-decoration:underline;"><a href="#" onClick={toggleFaq}>FaQ</a></h5>

        {showFaq ? <div className="m-3 pb-5">
              
        <h6 className="fw-bold">How it work?</h6>
        <p>Text your message, upload your file. Create a link to be encrypted. Send the link to the recipient. <br />
        The recipient can access the secret within 100 days by this link. After the link has been accessed, data will be deleted.</p>
        

        <h6 className="fw-bold">API</h6>
        <p>Available</p>
        <h6 className="fw-bold">Contact</h6>
        <p>Feel free to mail me: [myleochan63]@gmail.com
        
        </p>

        </div> : null}
   

      
       
        
    </div>



    
  )
}

export default Footer
